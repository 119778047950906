import { useUserStore } from "~/stores/user"
import { storeToRefs } from "pinia"
import { customPaths } from "~/utils/constants"

export default defineNuxtRouteMiddleware(async (to, from) => {
  const nuxtApp = await useNuxtApp()
  const checkSession = await useCheckSessionUser()
  const userStore = useUserStore()
  const { user } = storeToRefs(userStore)

  if (!checkSession) {
    return nuxtApp.runWithContext(() => navigateTo(customPaths.logInSignIn))
  } else {
    if (typeof checkSession === "object" && !user.value?.email)
      userStore.updateUserData(checkSession)
  }
})
